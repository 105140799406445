/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment, Grid, GridColumn } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
//import { useGoogleAnalytics } from 'volto-google-analytics';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  //useGoogleAnalytics()

  return (
    <div class="footer-container print_hide">
      <Segment
        role="contentinfo"
        vertical
        padded
        inverted
        id="footer"
        aria-label="Footer"
      >
        <Container>
          <div id="footer-main">
            <Grid padded stackable>
              <GridColumn width={4} textAlign="left">
                <div>
                  The SAMRC/Wits Centre for Health Economics and Decision
                  Science (PRICELESS SA) is a research-to-policy unit that
                  provides evidence, methodologies and tools for effective
                  decision-making in health.
                </div>
              </GridColumn>
              <GridColumn width={2} textAlign="left"></GridColumn>
              <GridColumn width={2} textAlign="left">
                <List>
                  <List.Header>
                    <h4>Quick links</h4>
                  </List.Header>
                  <List.Content>
                    <List.Item>
                      <UniversalLink href="/about/who-we-are">
                        Who we are
                      </UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/our-work">
                        Research to policy
                      </UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/projects">
                        Teaching & Learning
                      </UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/africa-connect">
                        Public engagement
                      </UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="/resources">Resources</UniversalLink>
                    </List.Item>
                  </List.Content>
                </List>
              </GridColumn>
              <GridColumn width={2} textAlign="left">
                <List>
                  <List.Header>
                    <h4>Where we are</h4>
                  </List.Header>
                  <List.Content>
                    <List.Item>
                      <div class="item">
                        Wits Education Campus, 27 St Andrews Road, Parktown,
                        Johannesburg
                      </div>
                      <UniversalLink href="/contact" target="_blank">
                        Contact Us
                      </UniversalLink>
                    </List.Item>
                    <hr />
                    <List.Item>
                      <List horizontal>
                        <List.Item>
                          <UniversalLink
                            href="https://www.linkedin.com/company/centre-for-risk-analysis"
                            target="_blank"
                          >
                            <i class="bi bi-linkedin"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://twitter.com/centre_risk"
                            target="_blank"
                          >
                            <i class="bi bi-twitter-x"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://www.facebook.com/CentreForRiskAnalysis/"
                            target="_blank"
                          >
                            <i class="bi bi-facebook"></i>
                          </UniversalLink>
                        </List.Item>
                        <List.Item>
                          <UniversalLink
                            href="https://www.youtube.com/channel/UCHRcn9vEaeKapV_EkfwFQJQ"
                            target="_blank"
                          >
                            <i class="bi bi-youtube"></i>
                          </UniversalLink>
                        </List.Item>
                      </List>
                    </List.Item>
                  </List.Content>
                </List>
              </GridColumn>
              <GridColumn width={2} textAlign="right" verticalAlign="bottom">
                <List>
                  <List.Content>
                    <List.Item>
                      <FormattedMessage
                        id="{copyright} PRICELESS {current_year}"
                        defaultMessage="{copyright} PRICELESS {current_year}"
                        values={{
                          copyright: (
                            <abbr
                              title={intl.formatMessage(messages.copyright)}
                            >
                              ©
                            </abbr>
                          ),
                          current_year: new Date().getFullYear(),
                        }}
                      />
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="#top" class="header-link">
                        <i class="bi bi-arrow-up-circle-fill"></i>
                      </UniversalLink>
                    </List.Item>
                  </List.Content>
                </List>
              </GridColumn>
            </Grid>
          </div>
        </Container>
      </Segment>
      <div>
        <Container textAlign="center">
          <div class="below-footer">
            CMS Website by{' '}
            <UniversalLink href="https://juizi.com" target="_blank">
              Juizi
            </UniversalLink>
          </div>
        </Container>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
